import { useEffect, useState } from "react"

export default function PopUp({isOpen, tem, setIsOpenPopUp}) {
    let [userName, setUserName] = useState("");
    let [phone, setPhone] = useState("");
    let [expertise, setExpertise] = useState("");
    let [description, setDescription] = useState("");
    let [nameError, setNameError] = useState(false);
    let [phoneError, setPhoneError] = useState(false);
    let [isInviteMemberVariant, setIsInviteMemberVariant] = useState(false);

    const MAX_USER_NAME = 50;

    useEffect(() => {
        if (tem.includes("Присоединяйся к нашей команде!")) {
            setIsInviteMemberVariant(true);
        }
    }, [tem])


    function _setUserName(_userName) {
        if (_userName.length < MAX_USER_NAME) {
            setUserName(_userName);
            setNameError(false);
        } else {
            setNameError(true);
        }
    }
 
    function _setPhone(_phone) {
        let _phoneInt = Number(_phone);
        if (Number.isInteger(_phoneInt)) {
            if (_phoneInt > 89000000000 && _phoneInt <= 89999999999) {
                setPhone(_phone);
                setPhoneError(false);
            } else {
                if (_phone.length < 11) {
                    setPhone(_phone);
                    setPhoneError(false);
                } else {
                    setPhoneError(true);
                } 
            }
        } else {
            setPhoneError(true);
        }
    }

    function clearState() {
        setUserName("");
        setPhone("");
        setExpertise("");
        setDescription("");
    }

    function closePopUp() {
        clearState();
        setIsOpenPopUp(false);
    }

    function downloadPolicy() {
        const pdfUrl = "privacy_policy.docx";
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.download = "privacy_policy.docx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    return (
        <div onClick={()=>{closePopUp()}} className={isOpen ? "modal opened" : "modal"}>
            <div onClick={(e)=>e.stopPropagation()} className="modal-body">
                <div className="popup_logo">
                    <img src={process.env.PUBLIC_URL + "/img/popup/logo.png"} alt="Логотип" />
                </div>
                <div className="popup_inform">
                    <p className="subtitle">{tem}</p>
                    <form className="popup_form" action="">
                        <input 
                            type="name" 
                            name="name" 
                            placeholder="Имя *" 
                            required
                            value={userName}
                            onChange={(e) => {_setUserName(e.target.value)}} 
                        />
                        <p className={`error user_name ${nameError ? "error_show" : ""}`}>Максимальная длина имени не может быть больше 50 символов!</p>
                        <input 
                            type="tel" 
                            name="phone" 
                            placeholder="Телефон *" 
                            required 
                            value={phone}
                            onChange={(e) => {_setPhone(e.target.value)}} 
                        />
                        <p className={`error user_phone ${phoneError ? "error_show" : ""}`}>Некорректный номер телефона!</p>
                        {!isInviteMemberVariant && (
                            <select 
                                placeholder="Экспертиза *" 
                                required
                                value={expertise}
                                onChange={(e) => {setExpertise(e.target.value)}}
                            >
                                <option value="" disabled>Экспертиза *</option>
                                <option value="Лаборатория строительно-технических и землеустроительных экспертиз">Лаборатория строительно-технических и землеустроительных экспертиз</option>
                                <option value="Лаборатория автотехнических и комплексных технических экспертиз">Лаборатория автотехнических и комплексных технических экспертиз</option>
                                <option value="Лаборатория товароведческих и финансово-экономических экспертиз">Лаборатория товароведческих и финансово-экономических экспертиз</option>
                                <option value="Лаборатория криминалистических и комплексных экспертиз">Лаборатория криминалистических и комплексных экспертиз</option>
                                {/* <option value="Консалдинговые услуги">Консалдинговые услуги</option> */}
                            </select>
                        )}
                        <textarea 
                            className="input_text" 
                            type="text" 
                            name="text" 
                            placeholder={isInviteMemberVariant ? "Сопроводительное письмо" : "По какому вопросу вы хотите получить консультацию"} 
                            minLength="10"
                            size="100" 
                            required 
                            value={description}
                            onChange={(e) => {setDescription(e.target.value)}} 
                        ></textarea>
                        <p className="popup_description description">Отправляя заявку, Вы соглашаетесь с <button className="privacy_policy" onClick={() => {downloadPolicy()}}>политикой конфиденциальности</button></p>
                        <button className="blue_button button">
                            Отправить
                            <img src={process.env.PUBLIC_URL + "/img/fourth_block/button_arrow.png"} alt="стрелка на кнопке" />
                        </button>
                    </form>
                </div>
            </div>
        </div>
    )
}
